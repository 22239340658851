<template>
  <div class="w-10/12 p-6 mx-auto">
    <div class="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-4 lg:max-w-none">
      <div
        v-for="button in filteredButtons"
        :key="button.code"
        class="flex flex-col overflow-hidden rounded-lg shadow-lg"
      >
        <div
          class="relative flex-shrink-0"
          @mouseenter="showVideoHover = button._id"
        >
          <div
            @click="showVideo(button)"
            class="absolute flex items-center justify-center w-full h-full text-white bg-indigo-600 bg-opacity-50"
            @mouseleave="showVideoHover = ''"
            v-if="showVideoHover === button._id && button.video.length > 0"
          >
            <button type="button">
              <PlayIcon class="w-12 h-12" aria-hidden="true" />
            </button>
          </div>
          <router-link
            tag="a"
            :to="{ name: 'Edit', params: { entry: JSON.stringify(button) } }"
            replace
            class="absolute right-1 top-1 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PencilIcon class="w-5 h-5" aria-hidden="true" />
          </router-link>
          <img
            class="object-cover w-full h-48"
            :src="url + button.cover + '?v=' + new Date()"
            :alt="button.title"
          />
        </div>
          
        <div
        :class="[
            button.active ? 'bg-white' : 'bg-gray-200',
            'flex flex-col justify-between flex-1',
          ]">
          <div class="flex-1 px-6 py-6">
            <a href="#" class="block" @click="showVideo(button)">
              <p class="text-xl font-semibold text-gray-900">
                {{button.titlefr}}
              </p>
              <p class="mt-3 text-base text-gray-500">
                {{button.descriptionfr}}
              </p>
            </a>
          </div>
          <div class="flex items-center justify-center px-6 py-2 bg-gray-50">
            <div class="flex items-center justify-center uppercase">
              <span class="text-xs font-semibold text-blue" v-if="button.type === 'button'">
                Bouton
              </span>
              <span class="text-xs font-semibold text-blue" v-if="button.type !== 'button'">
                Webservice
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal
    :title="currentButton.title"
    :open="showModal"
    @close="showModal = false"
  >
    <div>
      <video width="800" height="320" controls="controls" autoplay="autoplay">
        <source :src="currentButton.video" type="video/mp4" />
      </video>
    </div>
  </Modal>
</template>

<script>
import { PencilIcon, PlayIcon } from '@heroicons/vue/solid';
import Modal from '../components/Modal';
export default {
  components: {
    PencilIcon,
    PlayIcon,
    Modal,
  },
  data() {
    return {
      url: process.env.VUE_APP_BACKEND_URL,
      buttons: [],
      showVideoHover: '',
      search: '',
      showModal: false,
      currentButton: {
        title: '',
      },
    };
  },
  computed: {
    filteredButtons() {
      return this.buttons.filter((el) => {
        return (
          el.titlefr
            .toLowerCase()
            .includes(this.$attrs.searchvalue.toLowerCase()) ||
          el.descriptionfr
            .toLowerCase()
            .includes(this.$attrs.searchvalue.toLowerCase())
        );
      });
    },
  },
  mounted() {
    this.getButtons();
  },
  methods: {
    showVideo(button) {
      this.currentButton = button;
      this.showModal = true;
      this.showVideoHover = false;
    },
    getButtons() {
      this.$http
        .get('/buttons')
        .then((resp) => {
          this.buttons = resp.data;
        })
        .catch(() => {
          this.$store.dispatch('logout').then(() => {
            this.$router.push('/login');
          });
        });
    },
  },
};
</script>

<style></style>
