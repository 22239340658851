{
  "search" : "Search",
  "install" : "Install",
  "home" : "Home",
  "dashboard" : "Dashboard",
  "new-button" : "New flow",
  "save" : "Save",
  "cancel" : "Cancel",
  "type" : "Type",
  "title" : "Title",
  "description" : "Description",
  "category" : "Category",
  "cover-photo" : "Cover photo",
  "video" : "Video",
  "price" : "Price",
  "badge" : "Badge",
  "publish-date" : "Publish date",
  "active" : "Active",
  "tags" : "Tags"
}