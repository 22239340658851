<template>
  <div :class="[$attrs.showmenu ? 'w-10/12' : 'w-full', 'p-6 mx-auto']">
    <div v-if="!$attrs.showmenu" class="mb-3">
      <div class="flex items-center justify-center flex-1">
        <div class="w-full ">
          <label for="search" class="sr-only">Search</label>
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
            >
              <SearchIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="search"
              name="search"
              v-model="searchValue"
              class="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search"
              type="search"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      :class="[
        $attrs.showmenu ? 'mt-12' : '',
        'gap-5 grid lg:grid-cols-3 lg:max-w-none md:grid-cols-2 mt-3 mx-auto xl:grid-cols-4',
      ]"
    >
      <div class="relative flex flex-col overflow-hidden transition duration-300 ease-in-out transform rounded-lg shadow-lg hover:scale-102" v-for="button in filteredButtons" :key="button._id" @mouseenter="handleCardHover(button)" @mouseleave="showShopVideoHover = ''; showinstall = ''">
        <span class="z-10 ribbon1" v-if="button.price === 'Free'"><span class="text-sm font-semibold text-white">Gratuit</span></span>
        <div class="relative flex-shrink-0" @click="showVideo(button)">
          <div
            class="absolute flex items-center justify-center w-full h-full text-white bg-indigo-600 bg-opacity-50"
            v-if="showShopVideoHover === button._id"
          >
            <button type="button">
              <PlayIcon class="w-12 h-12" />
            </button>
          </div>
          <img class="object-cover w-full h-48" :src="url+button.cover" alt="">
        </div>
        <div class="flex flex-col justify-between flex-1 bg-white">
          <div class="flex-1 px-6 py-6">
            <a href="#" class="block" @click="showVideo(button)">
              <p class="text-xl font-semibold text-gray-900">
                {{button.titlefr}}
              </p>
              <p class="mt-3 text-base text-gray-500">
                {{button.descriptionfr}}
              </p>
            </a>
          </div>
          <div class="flex items-center justify-center px-6 py-2 bg-gray-50">
            <div class="flex items-center justify-center uppercase">
              <span class="text-xs font-semibold text-blue" v-if="button.type === 'button'">
                Bouton
              </span>
              <span class="text-xs font-semibold text-blue" v-if="button.type !== 'button'">
                Webservice
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal
    :title="currentButton.title"
    :open="showModal"
    @close="showModal = false"
  >
    <div>
      <video
        width="800"
        height="320"
        controls="controls"
        autoplay="autoplay"
        allowfullscreen
      >
        <source :src="currentButton.video" type="video/mp4" />
      </video>
    </div>
  </Modal>
</template>

<script>
import { PlayIcon, SearchIcon } from '@heroicons/vue/solid';
import Modal from '../components/Modal';
export default {
  components: {
    PlayIcon,
    SearchIcon,
    Modal,
  },
  data() {
    return {
      showShopVideoHover: '',
      showinstall: '',
      url: process.env.VUE_APP_BACKEND_URL,
      buttons: [],
      showVideoHover: '',
      search: '',
      searchValue: '',
      showModal: false,
      currentButton: {
        title: '',
      },
    };
  },
  computed: {
    searchQuery() {
      return this.$attrs.showmenu ? this.$attrs.searchvalue : this.searchValue;
    },
    filteredButtons() {
      return this.buttons.filter((el) => {
        return (
          el.titlefr.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          el.descriptionfr
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  mounted() {
    this.getButtons();
  },
  methods: {
    handleCardHover(button) {
      if(button.video !== '') {
        this.showShopVideoHover = button._id;
        this.showinstall = button._id
      } else {
        this.showinstall = button._id
      }
    },
    showVideo(button) {
      this.currentButton = button;
      this.showModal = true;
      this.showVideoHover = false;
    },
    getButtons() {
      this.$http.get('/active-buttons').then((resp) => {
        this.buttons = resp.data.buttons;
      });
    },
  },
};
</script>

<style></style>
