import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';
import 'flag-icon-css/css/flag-icon.min.css';
import { HTTP } from './http-common';
import i18n from './i18n';

const root = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
root.config.globalProperties.$http = HTTP;

const token = localStorage.getItem('token');
if (token) {
  root.config.globalProperties.$http.defaults.headers.common[
    'Authorization'
  ] = token;
}

root.mount('#app');
