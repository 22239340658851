{
  "search" : "Recherche",
  "install" : "Installer",
  "home" : "Accueil",
  "dashboard" : "Tableau de bord",
  "new-button" : "Nouveau flow",
  "save" : "Enregistrer",
  "cancel" : "Annuler",
  "type" : "Type",
  "title" : "Titre",
  "description" : "Description",
  "category" : "Catégorie",
  "cover-photo" : "Photo de couverture",
  "video" : "Vidéo",
  "price" : "Prix",
  "badge": "Badge",
  "publish-date" : "Date de publication",
  "active" : "Actif",
  "tags" : "Tags"
}