import { createStore } from 'vuex'
import axios from 'axios';
export default createStore({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, token) {
      state.status = 'success';
      state.token = token.token;
      state.user = token.user;
    },
    auth_error(state) {
      state.status = 'error';
    },
    set_user(state, user) {
      state.user = user;
    },
    logout(state) {
      console.log('Logging out!!');
      state.status = '';
      state.token = '';
      state.user = {};
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({
          url: process.env.VUE_APP_BACKEND_URL + '/login',
          data: user,
          method: 'POST',
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem('token', token);
            // Add the following line:
            axios.defaults.headers.common['Authorization'] = token;

            console.log(user);
            commit('auth_success', { token, user });
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            localStorage.removeItem('token');
            reject(err);
          });
      });
    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({
          url: process.env.VUE_APP_BACKEND_URL + '/register',
          data: user,
          method: 'POST',
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem('token', token);
            // Add the following line:
            axios.defaults.headers.common['Authorization'] = token;
            commit('auth_success', token, user);
            resolve(token);
          })
          .catch((err) => {
            commit('auth_error', err);
            localStorage.removeItem('token');
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        console.log('Logging out!!');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      });
    },
    setUser({commit}, user) {
      commit('set_user', user);
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    token: (state) => state.token,
  },
});
