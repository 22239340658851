<template>
  <Disclosure
    as="nav"
    class="bg-white shadow"
    v-slot="{ open }"
    v-if="$route.name !== 'Login' && showmenu"
  >
    <div class="px-2 mx-auto max-w-7xl sm:px-4 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex px-2 lg:px-0">
          <div class="flex items-center flex-shrink-0">
            <img
              class="block w-auto h-8 lg:hidden"
              src="https://skyged.mfpinthecloud.com/documents/img/logo.png"
              alt="Workflow"
            />
            <img
              class="hidden w-auto h-8 lg:block"
              src="https://skyged.mfpinthecloud.com/documents/img/logo.png"
              alt="Workflow"
            />
          </div>
          <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
            <!-- Current: "", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <router-link
              to="/"
              tag="a"
              :class="{
                'border-indigo-500 text-gray-900': $route.name === 'Home',
                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700':
                  $route.name !== 'Home',
              }"
              class="inline-flex items-center px-1 pt-1 text-sm font-medium border-b-2"
              >Accueil</router-link
            >
            <router-link
              v-if="isLoggedIn"
              to="/dashboard"
              tag="a"
              :class="{
                'border-indigo-500 text-gray-900': $route.name === 'Dashboard',
                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700':
                  $route.name !== 'Dashboard',
              }"
              class="inline-flex items-center px-1 pt-1 text-sm font-medium border-b-2"
              >Tableau de bord</router-link
            >
            <router-link
              v-if="isLoggedIn"
              to="/categories"
              tag="a"
              :class="{
                'border-indigo-500 text-gray-900': $route.name === 'Categories',
                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700':
                  $route.name !== 'Categories',
              }"
              class="inline-flex items-center px-1 pt-1 text-sm font-medium border-b-2"
              >Catégories</router-link
            >
          </div>
        </div>
        <div
          v-if="$route.name !== 'Create'"
          class="flex items-center justify-center flex-1 px-2 lg:ml-6 lg:justify-end"
        >
          <div class="w-full max-w-lg lg:max-w-xs">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <SearchIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                v-model="searchValue"
                class="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Rechercher"
                type="search"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block w-6 h-6" aria-hidden="true" />
            <XIcon v-else class="block w-6 h-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="hidden lg:ml-4 lg:flex lg:items-center">
          <router-link
            v-if="!isLoggedIn"
            to="/login"
            tag="a"
            class="inline-flex items-center px-4 py-2 ml-6 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Se connecter
          </router-link>
          <router-link
            v-if="$route.name === 'Dashboard'"
            to="/create"
            tag="a"
            class="inline-flex items-center px-4 py-2 ml-6 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusIcon class="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            Nouveau flow
          </router-link>
          <router-link
            to="/create-category"
            v-if="$route.name === 'Categories'"
            @click="catTrigger += 1"
            class="inline-flex items-center px-4 py-2 ml-6 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusIcon class="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            Nouvelle catégorie
          </router-link>
          <!-- Profile dropdown -->
          <Menu as="div" class="relative flex-shrink-0 ml-4" v-if="isLoggedIn">
            <div>
              <MenuButton
                class="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span class="sr-only">Open user menu</span>
                <img
                  class="w-8 h-8 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <!--<MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                    >Your Profile</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                    >Settings</a
                  >
                </MenuItem>-->
                <MenuItem v-slot="{ active }">
                  <router-link
                    to="/profile"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                    >Profile</router-link
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    @click.prevent="logout"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                    >Se déconnecter</a
                  >
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="lg:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" -->
        <router-link
          to="/"
          tag="a"
          :class="{
            'bg-indigo-50 border-indigo-500 text-indigo-700':
              $route.name === 'Home',
            'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800':
              $route.name !== 'Home',
          }"
          class="block py-2 pl-3 pr-4 text-base font-medium border-l-4"
          >Accueil</router-link
        >
        <router-link
          v-if="isLoggedIn"
          to="/dashboard"
          tag="a"
          :class="{
            'bg-indigo-50 border-indigo-500 text-indigo-700':
              $route.name === 'Dashboard',
            'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800':
              $route.name !== 'Dashboard',
          }"
          class="block py-2 pl-3 pr-4 text-base font-medium border-l-4"
          >Tablleau de bord</router-link
        >
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200" v-if="isLoggedIn">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <img
              class="w-10 h-10 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">
              {{ user.name }}
            </div>
            <div class="text-sm font-medium text-gray-500">
              {{ user.emaill }}
            </div>
          </div>
        </div>
        <div class="mt-3 space-y-1">
          <!--<a
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            >Your Profile</a
          >-->
          <router-link
            to="/profile"
            tag="a"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            >Profile</router-link
          >
          <a
            href="#"
            @click.prevent="logout"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            >Se déconnecter</a
          >
        </div>
      </div>

      <div class="px-3 pt-4 pb-3 border-t border-gray-200" v-else>
        <router-link
          to="/login"
          tag="a"
          class="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Se connecter
        </router-link>
      </div>
    </DisclosurePanel>
  </Disclosure>
  <router-view
    :searchvalue="searchValue"
    :showmenu="showmenu"
    :user="user"
    :cattrigger="catTrigger"
  />
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import { SearchIcon, PlusIcon } from '@heroicons/vue/solid';
import { MenuIcon, XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    SearchIcon,
    PlusIcon,
    XIcon,
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    user: function() {
      return this.$store.state.user;
    },
    token: function() {
      return this.$store.getters.token;
    },
  },
  methods: {
    logout: function() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },
    jwtDecode(t) {
      let token = {};
      token.raw = t;
      token.header = JSON.parse(window.atob(t.split('.')[0]));
      token.payload = JSON.parse(window.atob(t.split('.')[1]));
      return token.payload;
    },
  },
  created: function() {
    var vm = this;

    if (
      this.user &&
      Object.keys(this.user).length === 0 &&
      this.user.constructor === Object &&
      this.token.length > 0
    ) {
      var token = this.jwtDecode(this.token);
      vm.$store.dispatch('setUser', token);
    }
    this.$http.interceptors.response.use(undefined, function(err) {
      return new Promise(function() {
        if (
          err.response.status === 403 ||
          (err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest)
        ) {
          vm.$store.dispatch('logout');
          delete vm.$http.defaults.headers.common['Authorization'];
          vm.$router.push('/');
        }
        throw err;
      });
    });
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        var query = this.$route.query;
        if (query.ref && query.ref.length > 0) {
          this.showmenu = false;
        }
      });
    }, 0);
  },
  data() {
    return {
      open: false,
      searchValue: '',
      showmenu: true,
      catTrigger: 0,
    };
  },
};
</script>
