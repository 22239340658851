<template>
  <div class="px-5 pt-5 mx-auto max-w-7xl sm:px-0">
    <form
      class="space-y-8 divide-y divide-gray-200"
      @submit.prevent="saveEntry"
    >
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div>
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Nouvelle Category
            </h3>
          </div>

          <div class="w-full mx-auto mt-6" style="width:600px">
            <div
              class="flex items-center px-3 py-2 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
            >
              <label
                for="name"
                class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
              >
                <span class="flag-icon flag-icon-fr"></span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-11">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      v-model="entry.namefr"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="entry.namefr = ''"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <XCircleIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
            >
              <label
                for="name"
                class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
              >
                <span class="flag-icon flag-icon-us"></span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-11">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      v-model="entry.nameen"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="entry.nameen = ''"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <XCircleIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
            >
              <label
                for="name"
                class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
              >
                <span class="flag-icon flag-icon-it"></span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-11">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      v-model="entry.nameit"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="entry.nameit = ''"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <XCircleIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
            >
              <label
                for="name"
                class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
              >
                <span class="flag-icon flag-icon-es"></span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-11">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      v-model="entry.namees"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="entry.namees = ''"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <XCircleIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
            >
              <label
                for="name"
                class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
              >
                <span class="flag-icon flag-icon-de"></span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-11">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      v-model="entry.namede"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="entry.namede = ''"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <XCircleIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
            >
              <label
                for="name"
                class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
              >
                <span class="flag-icon flag-icon-nl"></span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-11">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      v-model="entry.namenl"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="entry.namenl = ''"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <XCircleIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
            >
              <label
                for="name"
                class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
              >
                <span class="flag-icon flag-icon-pt"></span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-11">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      v-model="entry.namept"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="entry.namept = ''"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <XCircleIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
            >
              <label
                for="name"
                class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
              >
                <span class="flag-icon flag-icon-ru"></span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-11">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      v-model="entry.nameru"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="entry.nameru = ''"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <XCircleIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
            >
              <label
                for="name"
                class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
              >
                <span class="flag-icon flag-icon-cn"></span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-11">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      v-model="entry.namecn"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="entry.namecn = ''"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <XCircleIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <router-link
            tag="a"
            to="/categories"
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Annuler
          </router-link>
          <button
            type="button"
            @click="translate('name')"
            class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Traduire
          </button>
          <button
            type="submit"
            class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sauvegarder
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import { XCircleIcon } from '@heroicons/vue/solid';
export default {
  components: {
    XCircleIcon,
  },
  data() {
    return {
      deeplapi: process.env.VUE_APP_DEEPL_API,
      entry: {
        namefr: '',
        nameen: '',
        namees: '',
        namede: '',
        nameit: '',
        namept: '',
        namecn: '',
        nameru: '',
        namenl: '',
      },
    };
  },
  methods: {
    translate(field) {
      var langs = ['en', 'it', 'es', 'pt', 'nl', 'cn', 'ru', 'de'];
      langs.map((el) => {
        var target = el.toUpperCase();
        if (el === 'cn') {
          target = 'ZH';
        }
        axios
          .get(
            'https://api.deepl.com/v2/translate?auth_key=' +
              this.deeplapi +
              '&source_lang=FR&target_lang=' +
              target +
              '&text=' +
              this.entry[field + 'fr'],
          )
          .then((resp) => {
            this.entry[field + el] = resp.data.translations[0].text;
          });
      });
    },
    saveEntry() {
      if (
        this.entry.namefr.length > 0
      ) {
        let formData = new FormData();
        formData.append('namefr', this.entry.namefr);
        formData.append('nameen', this.entry.nameen);
        formData.append('nameit', this.entry.nameit);
        formData.append('namees', this.entry.namees);
        formData.append('namept', this.entry.namept);
        formData.append('namede', this.entry.namede);
        formData.append('namenl', this.entry.namenl);
        formData.append('nameru', this.entry.nameru);
        formData.append('namecn', this.entry.namecn);
        this.$http
          .post('/category', formData)
          .then(() => {
            this.$router.replace('/categories');
          })
          .catch((e) => {
            console.error(JSON.stringify(e));
          });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'error',
          title: 'Please check all the required fields',
        });
      }
    },
  },
};
</script>
