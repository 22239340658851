<template>
  <div class="w-10/12 p-6 mx-auto">
    <div class="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-4 lg:max-w-none">
      <div
        v-for="button in filteredButtons"
        :key="button.code"
        class="flex flex-col overflow-hidden rounded-lg shadow-lg"
      >
        <div
          class="relative flex-shrink-0"
          @mouseenter="showVideoHover = button._id"
        >
          <div
            @click="showVideo(button)"
            class="absolute flex items-center justify-center w-full h-full text-white bg-indigo-600 bg-opacity-50"
            @mouseleave="showVideoHover = ''"
            v-if="showVideoHover === button._id && button.video.length > 0"
          >
            <button type="button">
              <PlayIcon class="w-12 h-12" aria-hidden="true" />
            </button>
          </div>
          <img
            class="object-cover w-full h-48"
            :src="url + button.cover + '?v=' + new Date()"
            :alt="button.title"
          />
        </div>
        <div
          class="flex flex-col justify-between flex-1 p-6 bg-white shadow-2xl"
        >
          <div class="flex-1">
            <a href="#" class="block mt-2">
              <p class="text-xl font-semibold text-gray-900">
                {{ button.title }}
              </p>
              <p class="mt-3 text-base text-gray-500">
                {{ button.description }}
              </p>
            </a>
          </div>
          <div class="pt-6 mt-3 border-t">
            <div class="flex items-center justify-between">
              <div>
                <p class="text-sm font-medium text-indigo-600">
                  <a href="#" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 capitalize">
                    {{ button.badge }}
                  </a>
                </p>
              </div>
              <div>
                <button
                type="button"
                class="inline-flex items-center px-4 py-2 ml-6 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <PlayIcon class="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
                Install
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal
    :title="currentButton.title"
    :open="showModal"
    @close="
      showModal = false;
    "
  >
  <div>
      <video width="800" height="320" controls="controls" autoplay="autoplay"><source :src="currentButton.video" type="video/mp4"/></video>
  </div>
  </Modal>
</template>

<script>
import { PlayIcon } from '@heroicons/vue/solid';
import Modal from '../components/Modal';
export default {
  components: {
    PlayIcon,
    Modal
  },
  data() {
    return {
      url : process.env.VUE_APP_BACKEND_URL,
      buttons: [],
      showVideoHover: '',
      search: '',
      showModal: false,
      currentButton: {
        title: '',
      },
    };
  },
  computed: {
    filteredButtons() {
      return this.buttons.filter((el) => {
        return (
          el.title
            .toLowerCase()
            .includes(this.$attrs.searchvalue.toLowerCase()) ||
          el.description
            .toLowerCase()
            .includes(this.$attrs.searchvalue.toLowerCase())
        );
      });
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');
    console.log(ref)
    console.log(this.$route.query)
    this.getButtons();
  },
  methods: {
    showVideo(button) {
      this.currentButton = button;
      this.showModal = true;
      this.showVideoHover = false;
    },
    getButtons() {
      this.$http.get('/active-buttons').then((resp) => {
        this.buttons = resp.data;
      });
    },
  },
};
</script>

<style></style>