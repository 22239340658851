<template>
  <div class="px-5 py-5 mx-auto max-w-7xl sm:px-0" :key="formKey">
    <form
      class="space-y-8 divide-y divide-gray-200"
      @submit.prevent="saveEntry"
    >
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div>
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Modification d'un flow
            </h3>
          </div>

          <div class="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="type"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Type <span class="text-red-500">*</span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <select
                    id="type"
                    name="type"
                    v-model="entry.type"
                    autocomplete="type"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="button">Button</option>
                    <option value="webservice">Webservice</option>
                  </select>
                </div>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="title"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Titre <span class="text-red-500">*</span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <div
                    class="relative flex items-stretch flex-grow focus-within:z-10"
                  >
                    <input
                      type="text"
                      name="title"
                      id="title"
                      v-model="entry.titlefr"
                      class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    @click="showTitleModal = true"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <GlobeAltIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="category"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Catégorie
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <select
                    id="badge"
                    name="badge"
                    v-model="entry.category"
                    autocomplete="badge"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option
                      :value="category._id"
                      v-for="category in categories"
                      :key="category._id"
                      >{{ category.namefr }}</option
                    >
                  </select>
                </div>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="description"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Description <span class="text-red-500">*</span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <textarea
                    id="description"
                    name="description"
                    v-model="entry.descriptionfr"
                    rows="3"
                    class="block w-full max-w-lg border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />

                  <button
                    type="button"
                    @click="showDescriptionModal = true"
                    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <GlobeAltIcon class="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="cover_photo"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Photo de couverture (400x225)
                <span class="text-red-500">*</span>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div
                  class="flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                  :class="{ 'bg-gray-200': isDragging }"
                  @drop.prevent="addFile"
                  @dragover.prevent="isDragging = true"
                  @dragenter.prevent="isDragging = true"
                  @dragleave.prevent="isDragging = false"
                >
                  <div
                    class="space-y-1 text-center"
                    v-if="covers.length === 0 && entry.cover === ''"
                  >
                    <svg
                      class="w-12 h-12 mx-auto text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div class="flex text-sm text-gray-600">
                      <label
                        for="file-upload"
                        :class="{
                          'bg-gray-200': isDragging,
                          'bg-white': !isDragging,
                        }"
                        class="relative font-medium text-indigo-600 rounded-md cursor-pointer hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input
                          @change="processImage"
                          accept="image/*"
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          class="sr-only"
                        />
                      </label>
                      <p class="pl-1">or drag and drop</p>
                    </div>
                    <p
                      class="text-xs"
                      :class="{
                        'text-white': isDragging,
                        'text-gray-500': !isDragging,
                      }"
                    >
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                  <div v-else class="relative">
                    <button
                      v-if="covers.length > 0"
                      @click.prevent="covers = []"
                      type="button"
                      class="absolute right-1 top-1 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <TrashIcon class="w-5 h-5" aria-hidden="true" />
                    </button>
                    <img
                      ref="coverimage"
                      id="coverimage"
                      v-if="covers.length > 0"
                    />
                    <img
                      :src="url + entry.cover + '?v=' + new Date()"
                      v-else-if="entry.cover.length > 0"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="video"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Vidéo
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="video"
                    v-model="entry.video"
                    id="video"
                    autocomplete="video"
                    class="flex-1 block w-full min-w-0 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="price"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Prix
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="price"
                    v-model="entry.price"
                    id="price"
                    disabled
                    class="flex-1 block w-full min-w-0 bg-gray-200 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="badge"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Badge
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <select
                    id="badge"
                    name="badge"
                    v-model="entry.badge"
                    autocomplete="badge"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="new">New</option>
                    <option value="updated">Updated</option>
                    <option value="nobadge" selected>No badge</option>
                  </select>
                </div>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="publish_date"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Date de publication
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <DatePicker v-model="entry.publishdate">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        name="publishdate"
                        :value="inputValue"
                        v-on="inputEvents"
                        id="publishdate"
                        autocomplete="publishdate"
                        class="flex-1 block w-full min-w-0 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </template>
                </DatePicker>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="active"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Variante
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div
                  class="flex flex-col overflow-hidden border rounded-md shadow-sm"
                >
                  <div
                    class="px-4 py-5 bg-white border-b border-gray-200 sm:px-6"
                  >
                    <div
                      class="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap"
                    >
                      <div class="mt-2 ml-4">
                        <h3
                          class="text-lg font-medium leading-6 text-gray-900"
                          v-if="entry.variants.length === 1"
                        >
                          {{ entry.variants.length }} Variante
                        </h3>
                        <h3
                          class="text-lg font-medium leading-6 text-gray-900"
                          v-else
                        >
                          {{ entry.variants.length }} Variantes
                        </h3>
                      </div>
                      <div class="flex-shrink-0 mt-2 ml-4">
                        <button
                          type="button"
                          @click="addNewVariant"
                          class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Nouvelle variante
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                      class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                    >
                      <div class="overflow-hidden border-b border-gray-200">
                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                              >
                                Name
                              </th>
                              <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(variant, variantIdx) in entry.variants"
                              :key="variant.name"
                              :class="
                                variantIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              "
                            >
                              <td
                                class="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap"
                              >
                                {{ variant.namefr }}
                              </td>
                              <td
                                class="px-6 py-4 text-sm font-medium text-right whitespace-nowrap"
                              >
                                <a
                                  href="#"
                                  @click.prevent="editVariant(variant)"
                                  class="text-indigo-600 hover:text-indigo-900"
                                  >Modifier</a
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="active"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Actif
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex max-w-lg rounded-md shadow-sm">
                  <button
                    type="button"
                    @click="entry.active = !entry.active"
                    :class="[
                      entry.active ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                    ]"
                    role="switch"
                    aria-checked="false"
                  >
                    <span class="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        entry.active ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                      ]"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex items-center justify-between">
          <div>
            <button
              type="button"
              @click.prevent="deleteEntry"
              class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Supprimer
            </button>
          </div>
          <div>
            <router-link
              tag="button"
              to="/dashboard"
              type="button"
              class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Annuler
            </router-link>
            <button
              type="submit"
              class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sauvegarder
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <Modal
    :title="entry.titlefr"
    :open="showTitleModal"
    :nocancel="true"
    @close="showTitleModal = false"
  >
    <div>
      <div style="width:600px;">
        <div
          class="flex items-center px-3 py-2 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-fr"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.titlefr"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.titlefr = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-us"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.titleen"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.titleen = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-it"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.titleit"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.titleit = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-es"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.titlees"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.titlees = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-de"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.titlede"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.titlede = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-nl"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.titlenl"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.titlenl = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-pt"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.titlept"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.titlept = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-ru"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.titleru"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.titleru = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-cn"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.titlecn"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.titlecn = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="px-3 mt-5">
        <div class="flex justify-between">
          <span class="inline-flex max-w-lg rounded-md shadow-sm">
            <button
              @click="translate('title')"
              type="button"
              class="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700"
            >
              Traduire
            </button>
          </span>

          <span class="inline-flex max-w-lg rounded-md shadow-sm">
            <button
              @click="showTitleModal = false"
              type="button"
              class="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700"
            >
              Valider
            </button>
          </span>
        </div>
      </div>
    </div>
  </Modal>

  <Modal
    :title="entry.titlefr"
    :open="showDescriptionModal"
    :nocancel="true"
    @close="showDescriptionModal = false"
  >
    <div>
      <div style="width:600px;">
        <div
          class="flex items-center px-3 py-2 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-fr"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.descriptionfr"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.descriptionfr = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-us"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.descriptionen"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.descriptionen = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-it"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.descriptionit"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.descriptionit = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-es"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.descriptiones"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.descriptiones = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-de"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.descriptionde"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.descriptionde = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-nl"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.descriptionnl"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.descriptionnl = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-pt"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.descriptionpt"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.descriptionpt = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-ru"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.descriptionru"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.descriptionru = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-cn"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="entry.descriptioncn"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="entry.descriptioncn = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="px-3 mt-5">
        <div class="flex justify-between">
          <span class="inline-flex max-w-lg rounded-md shadow-sm">
            <button
              @click="translate('description')"
              type="button"
              class="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700"
            >
              Traduire
            </button>
          </span>

          <span class="inline-flex max-w-lg rounded-md shadow-sm">
            <button
              @click="showDescriptionModal = false"
              type="button"
              class="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700"
            >
              Valider
            </button>
          </span>
        </div>
      </div>
    </div>
  </Modal>

  <Modal
    v-if="showVariantTitleTranslation"
    :title="entry.titlefr"
    :open="showVariantTitleTranslation"
    :nocancel="true"
    @close="showVariantTitleTranslation = false"
  >
    <div>
      <div style="width:600px;">
        <div
          class="flex items-center px-3 py-2 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-fr"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="newvariant.namefr"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="newvariant.namefr = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-us"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="newvariant.nameen"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="newvariant.nameen = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-it"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="newvariant.nameit"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="newvariant.nameit = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-es"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="newvariant.namees"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="newvariant.namees = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-de"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="newvariant.namede"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="newvariant.namede = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-nl"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="newvariant.namenl"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="newvariant.namenl = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-pt"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="newvariant.namept"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="newvariant.namept = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-ru"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="newvariant.nameru"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="newvariant.nameru = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
        >
          <label
            for="name"
            class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
          >
            <span class="flag-icon flag-icon-cn"></span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-11">
            <div class="flex max-w-lg rounded-md shadow-sm">
              <div
                class="relative flex items-stretch flex-grow focus-within:z-10"
              >
                <input
                  type="text"
                  v-model="newvariant.namecn"
                  class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                @click="newvariant.namecn = ''"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="px-3 mt-5">
        <div class="flex justify-between">
          <span class="inline-flex max-w-lg rounded-md shadow-sm">
            <button
              @click="translateVariant('name')"
              type="button"
              class="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700"
            >
              Traduire
            </button>
          </span>

          <span class="inline-flex max-w-lg rounded-md shadow-sm">
            <button
              @click="
                showVariantTitleTranslation = false;
                showAddVariantModal = true;
              "
              type="button"
              class="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700"
            >
              Valider
            </button>
          </span>
        </div>
      </div>
    </div>
  </Modal>

  <Modal
    ref="addvariantmodal"
    :title="'Variante de ' + entry.titlefr + ' : ' + newvariant.namefr"
    :open="showAddVariantModal"
    :nocancel="true"
    @close="showAddVariantModal = false"
  >
    <div style="width:600px">
      <!-- sm:border-t sm:border-gray-200 sm:pt-5 -->
      <div
        class="flex items-center px-3 py-2 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
      >
        <label
          for="name"
          class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
        >
          <span class="flag-icon flag-icon-fr"></span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-11">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                v-model="newvariant.namefr"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="button"
              @click="newvariant.namefr = ''"
              class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <XCircleIcon class="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
      >
        <label
          for="name"
          class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
        >
          <span class="flag-icon flag-icon-us"></span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-11">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                v-model="newvariant.nameen"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="button"
              @click="newvariant.nameen = ''"
              class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <XCircleIcon class="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
      >
        <label
          for="name"
          class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
        >
          <span class="flag-icon flag-icon-it"></span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-11">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                v-model="newvariant.nameit"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="button"
              @click="newvariant.nameit = ''"
              class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <XCircleIcon class="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
      >
        <label
          for="name"
          class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
        >
          <span class="flag-icon flag-icon-es"></span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-11">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                v-model="newvariant.namees"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="button"
              @click="newvariant.namees = ''"
              class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <XCircleIcon class="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
      >
        <label
          for="name"
          class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
        >
          <span class="flag-icon flag-icon-de"></span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-11">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                v-model="newvariant.namede"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="button"
              @click="newvariant.namede = ''"
              class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <XCircleIcon class="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
      >
        <label
          for="name"
          class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
        >
          <span class="flag-icon flag-icon-nl"></span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-11">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                v-model="newvariant.namenl"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="button"
              @click="newvariant.namenl = ''"
              class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <XCircleIcon class="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
      >
        <label
          for="name"
          class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
        >
          <span class="flag-icon flag-icon-pt"></span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-11">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                v-model="newvariant.namept"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="button"
              @click="newvariant.namept = ''"
              class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <XCircleIcon class="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
      >
        <label
          for="name"
          class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
        >
          <span class="flag-icon flag-icon-ru"></span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-11">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                v-model="newvariant.nameru"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="button"
              @click="newvariant.nameru = ''"
              class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <XCircleIcon class="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="flex items-center px-3 py-2 mt-1 rounded-md sm:gap-4 sm:grid sm:grid-cols-12"
      >
        <label
          for="name"
          class="flex items-center justify-center block text-sm font-medium leading-5 text-gray-700"
        >
          <span class="flag-icon flag-icon-cn"></span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-11">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                v-model="newvariant.namecn"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="button"
              @click="newvariant.namecn = ''"
              class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <XCircleIcon class="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="mt-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
      >
        <label
          for="cover_photo"
          class="flex items-center w-full h-full text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          JSON
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <div
            class="flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
            :class="{ 'bg-gray-200': isJsonDragging }"
            @drop.prevent="addJsonFile"
            @dragover.prevent="isJsonDragging = true"
            @dragenter.prevent="isJsonDragging = true"
            @dragleave.prevent="isJsonDragging = false"
          >
            <div class="space-y-1 text-center" v-if="jsonfiles.length === 0">
              <svg
                class="w-12 h-12 mx-auto text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div class="flex text-sm text-gray-600">
                <label
                  for="json-file-upload"
                  :class="{
                    'bg-gray-200': isJsonDragging,
                    'bg-white': !isJsonDragging,
                  }"
                  class="relative font-medium text-indigo-600 rounded-md cursor-pointer hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Upload a file</span>
                  <input
                    @change="processJson"
                    accept="application/JSON"
                    id="json-file-upload"
                    name="json-file-upload"
                    type="file"
                    class="sr-only"
                  />
                </label>
                <p class="pl-1">or drag and drop</p>
              </div>
              <p
                class="text-xs"
                :class="{
                  'text-white': isJsonDragging,
                  'text-gray-500': !isJsonDragging,
                }"
              >
                JSON up to 10MB
              </p>
            </div>
            <div v-else class="relative">
              <button
                v-if="jsonfiles.length > 0"
                @click.prevent="jsonfiles = []"
                type="button"
                class="-right-5 -top-4 absolute bg-red-600 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700 inline-flex items-center p-1.5 rounded-full shadow-sm text-white"
              >
                <TrashIcon class="w-5 h-5" aria-hidden="true" />
              </button>
              <span class="inline-block p-4 bg-white rounded-md shadow-md">
                <svg
                  aria-hidden="true"
                  data-prefix="fal"
                  data-icon="file-code"
                  class="h-24 text-indigo-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M369.941 97.941l-83.882-83.882A48 48 0 00252.118 0H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48V131.882a48 48 0 00-14.059-33.941zm-22.627 22.628a15.89 15.89 0 014.195 7.431H256V32.491a15.88 15.88 0 017.431 4.195l83.883 83.883zM336 480H48c-8.837 0-16-7.163-16-16V48c0-8.837 7.163-16 16-16h176v104c0 13.255 10.745 24 24 24h104v304c0 8.837-7.163 16-16 16zm-161.471-67.404l-25.928-7.527a5.1 5.1 0 01-3.476-6.32l58.027-199.869a5.1 5.1 0 016.32-3.476l25.927 7.527a5.1 5.1 0 013.476 6.32L180.849 409.12a5.1 5.1 0 01-6.32 3.476zm-48.446-47.674l18.492-19.724a5.101 5.101 0 00-.351-7.317L105.725 304l38.498-33.881a5.1 5.1 0 00.351-7.317l-18.492-19.724a5.1 5.1 0 00-7.209-.233L57.61 300.279a5.1 5.1 0 000 7.441l61.263 57.434a5.1 5.1 0 007.21-.232zm139.043.232l61.262-57.434a5.1 5.1 0 000-7.441l-61.262-57.434a5.1 5.1 0 00-7.209.233l-18.492 19.724a5.101 5.101 0 00.351 7.317L278.275 304l-38.499 33.881a5.1 5.1 0 00-.351 7.317l18.492 19.724a5.1 5.1 0 007.209.232z"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div v-if="newvariant.jsonfile && newvariant.jsonfile.length > 0" class="mt-3">
            <input type="text" :value="newvariant.jsonfile" disabled class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="you@example.com" />
          </div>
        </div>
      </div>

      <div
        class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:mt-5"
      >
        <label
          for="version"
          class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Version
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <div class="flex max-w-lg rounded-md shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                type="text"
                name="version"
                id="version"
                v-model="newvariant.version"
                class="flex-1 block w-full min-w-0 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex items-center justify-between">
          <div>
            <button
              v-if="entry.variants.some((el) => el.id === newvariant.id)"
              type="button"
              @click.prevent="
                deleteVariantJson();
                entry.variants = entry.variants.filter(
                  (el) => el.id !== newvariant.id,
                );
                showAddVariantModal = false;
              "
              class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Supprimer
            </button>
          </div>
          <div>
            <button
              @click="showAddVariantModal = false"
              type="button"
              class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Annuler
            </button>
            <button
              type="button"
              @click="translateVariant('name')"
              class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Traduire
            </button>
            <button
              type="button"
              @click="saveVariant"
              class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sauvegarder
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Modal from '../components/Modal';
import { TrashIcon, GlobeAltIcon, XCircleIcon } from '@heroicons/vue/solid';
import { DatePicker } from 'v-calendar';
import Swal from 'sweetalert2';
export default {
  components: {
    TrashIcon,
    DatePicker,
    GlobeAltIcon,
    XCircleIcon,
    Modal,
  },
  data() {
    return {
      url: process.env.VUE_APP_BACKEND_URL,
      showVariantTitleTranslation: false,
      showTitleModal: false,
      showDescriptionModal: false,
      showAddVariantModal: false,
      deeplapi: process.env.VUE_APP_DEEPL_API,
      categories: [],
      newvariant: { name: '' },
      entry: {
        type: 'button',
        titlefr: '',
        titleen: '',
        titlees: '',
        titlede: '',
        titleit: '',
        titlept: '',
        titlecn: '',
        titleru: '',
        titlenl: '',
        descriptionfr: '',
        descriptionen: '',
        descriptiones: '',
        descriptionde: '',
        descriptionit: '',
        descriptionpt: '',
        descriptioncn: '',
        descriptionru: '',
        descriptionnl: '',
        category: '',
        price: 'Free',
        cover: '',
        video: '',
        badge: 'nobadge',
        publishdate: '',
        active: false,
        variants: [],
      },
      covers: [],
      jsonfiles: [],
      isDragging: false,
      formKey: 1,
    };
  },
  created() {
    this.entry = JSON.parse(this.$route.params.entry);

    if (this.entry.publishdate && this.entry.publishdate.length > 0) {
      this.entry.publishdate = new Date(this.entry.publishdate);
    }

    this.getCategories();
  },
  computed: {
    uploadDisabled() {
      return this.covers.length === 0;
    },
  },
  methods: {
    deleteVariantJson() {
      if (this.newvariant.jsonfile && this.newvariant.jsonfile.length > 0) {
        var formdata = new FormData();
        formdata.append('json', this.newvariant.jsonfile);

        this.$http.post('/delete-json', formdata);
      }
    },
    addJsonFile(e) {
      this.isJsonDragging = false;
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;

      if (![...droppedFiles][0].type.includes('json')) {
        return;
      }

      this.jsonfiles = [[...droppedFiles][0]];
    },
    processJson(e) {
      if (!e || !e.target || !e.target.files[0].type.includes('json')) {
        return;
      }

      this.jsonfiles = [e.target.files[0]];
    },
    saveVariant() {
      this.showVariantTitleTranslation = false;
      this.showAddVariantModal = false;

      if (this.jsonfiles.length > 0) {
        var formdata = new FormData();
        formdata.append('json', this.jsonfiles[0]);

        this.$http.post('/upload-json', formdata).then((repsonse) => {
          this.newvariant.jsonfile = repsonse.data.url;
          this.jsonfiles = [];
          if (this.entry.variants.some((el) => el.id === this.newvariant.id)) {
            this.entry.variants.forEach((el) => {
              if (el.id === this.newvariant.id) {
                el = this.newvariant;
              }
            });
          } else {
            this.entry.variants.push(this.newvariant);
          }
          this.showAddVariantModal = false;
          this.saveEntry(true);
        });
      } else {
        if (this.entry.variants.some((el) => el.id === this.newvariant.id)) {
          this.entry.variants.forEach((el) => {
            if (el.id === this.newvariant.id) {
              el = this.newvariant;
            }
          });
        } else {
          this.entry.variants.push(this.newvariant);
        }
        this.showAddVariantModal = false;
        this.saveEntry(true);
        this.$nextTick(() => {
          this.formKey += 1;
          this.$forceUpdate();
        });
      }
    },
    addNewVariant() {
      this.showAddVariantModal = true;
      this.newvariant = {
        id: uuidv4(),
        namefr: '',
        nameen: '',
        namees: '',
        nameit: '',
        namenl: '',
        namept: '',
        namede: '',
        nameru: '',
        namecn: '',
        jsonfile: '',
      };
    },
    editVariant(variant) {
      this.newvariant = variant;
      this.showAddVariantModal = true;
    },
    getCategories() {
      this.$http.get('/category').then((resp) => {
        this.categories = resp.data;
      });
    },
    translate(field) {
      var langs = ['en', 'it', 'es', 'pt', 'nl', 'cn', 'ru', 'de'];
      langs.map((el) => {
        var target = el.toUpperCase();
        if (el === 'cn') {
          target = 'ZH';
        }
        var data = {
          auth_key: this.deeplapi,
          source_lang: "FR",
          text: this.entry[field + 'fr'],
          target_lang: target
        }
        this.$http.post('/translate', data).then((resp) => {
            this.entry[field + el] = resp.data.translations[0].text;
          })
          .catch(e=>console.log(e));
      });
    },
    translateVariant(field) {
      var langs = ['en', 'it', 'es', 'pt', 'nl', 'cn', 'ru', 'de'];
      langs.map((el) => {
        var target = el.toUpperCase();
        if (el === 'cn') {
          target = 'ZH';
        }
        var data = {
          source_lang: "FR",
          auth_key: this.deeplapi,
          text: this.newvariant[field + 'fr'],
          target_lang: target
        }
        this.$http.post('/translate', data).then((resp) => {
            this.newvariant[field + el] = resp.data.translations[0].text;
          })
          .catch(e=>console.log(e));
      });
    },
    kb(ammout) {
      return Math.floor(ammout / 1024);
    },
    addFile(e) {
      this.isDragging = false;
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      /*[...droppedFiles].forEach((f) => {
        this.covers.push(f);
      });*/

      if (![...droppedFiles][0].type.includes('image')) {
        return;
      }

      this.covers = [[...droppedFiles][0]];

      this.$nextTick(() => {
        this.$refs.coverimage.src = URL.createObjectURL(this.covers[0]);
      });
    },
    processImage(e) {
      if (!e || !e.target || !e.target.files[0].type.includes('image')) {
        return;
      }

      this.covers = [e.target.files[0]];

      this.$nextTick(() => {
        this.$refs.coverimage.src = URL.createObjectURL(this.covers[0]);
      });
    },
    removeFile() {
      this.covers = [];
    },
    deleteEntry() {
      var vm = this;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          vm.$http
            .delete('/buttons', {
              data: vm.entry,
            })
            .then(() => {
              vm.$router.replace({
                name: 'Dashboard',
                params: { reload: true },
              });
            });
        }
      });
    },
    saveEntry(reload) {
      let formData = new FormData();
      if (
        (this.covers.length > 0 || this.entry.cover.length > 0) &&
        this.entry.type.length > 0 &&
        this.entry.titlefr.length > 0 &&
        this.entry.descriptionfr.length > 0
      ) {
        if (this.covers.length > 0) {
          formData.append('file', this.covers[0]);
        }
        formData.append('id', this.entry._id);
        formData.append('type', this.entry.type);
        formData.append('titlefr', this.entry.titlefr);
        formData.append('titleen', this.entry.titleen);
        formData.append('titleit', this.entry.titleit);
        formData.append('titlees', this.entry.titlees);
        formData.append('titlept', this.entry.titlept);
        formData.append('titlede', this.entry.titlede);
        formData.append('titlenl', this.entry.titlenl);
        formData.append('titleru', this.entry.titleru);
        formData.append('titlecn', this.entry.titlecn);
        formData.append('publishdate', this.entry.publishdate);
        formData.append('descriptionfr', this.entry.descriptionfr);
        formData.append('descriptionen', this.entry.descriptionen);
        formData.append('descriptionit', this.entry.descriptionit);
        formData.append('descriptiones', this.entry.descriptiones);
        formData.append('descriptionpt', this.entry.descriptionpt);
        formData.append('descriptionde', this.entry.descriptionde);
        formData.append('descriptionnl', this.entry.descriptionnl);
        formData.append('descriptionru', this.entry.descriptionru);
        formData.append('descriptioncn', this.entry.descriptioncn);
        formData.append('category', this.entry.category);
        formData.append('code', this.entry.code);
        formData.append('price', this.entry.price);
        formData.append('cover', this.entry.cover);
        formData.append('video', this.entry.video);
        formData.append('active', this.entry.active);
        formData.append('badge', this.entry.badge);
        formData.append('variants', JSON.stringify(this.entry.variants));
        this.$http
          .put('/buttons', formData)
          .then(() => {
            if(reload !== true) {
              this.$router.replace({
                name: 'Dashboard',
                params: { reload: true },
              });
            }
          })
          .catch((e) => {
            console.error(JSON.stringify(e));
          });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'error',
          title: 'Please check all the required fields',
        });
      }
    },
  },
};
</script>
