<template>
  <div class="max-w-6xl mx-auto mt-6">
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Nom
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="categories.length > 0">
                <tr
                  v-for="(category, categoryIdx) in filteredCategories"
                  :key="category.email"
                  :class="categoryIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap"
                  >
                    {{ category.namefr }}
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium text-right whitespace-nowrap"
                  >
                    <router-link
                      tag="a"
                      :to="{ name: 'EditCategory', params: { category: JSON.stringify(category) } }"
                      replace
                      class="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-indigo-600 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Modifier
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="categories.length === 0" class="w-full">
              <span class="block text-sm text-center text-gray-600">Pas de catégories</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['cattrigger'],
  watch: {
    cattrigger() {
      console.log('triggering new cat')
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_BACKEND_URL,
      categories: [],
      search: '',
    };
  },
  computed: {
    filteredCategories() {
      return this.categories.filter((el) => {
        return el.namefr
          .toLowerCase()
          .includes(this.$attrs.searchvalue.toLowerCase());
      });
    },
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    showVideo(button) {
      this.currentButton = button;
      this.showModal = true;
      this.showVideoHover = false;
    },
    getCategories() {
      this.$http
        .get('/category')
        .then((resp) => {
          this.categories = resp.data;
        })
        .catch(() => {
          /*this.$store.dispatch('logout').then(() => {
            this.$router.push('/login');
          });*/
        });
    },
  },
};
</script>

<style></style>
