import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Iframe from '../views/Iframe.vue';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import Create from '../views/Create.vue';
import CreateCategory from '../views/CreateCategory.vue';
import Profile from '../views/Profile.vue';
import Categories from '../views/Categories.vue';
import Edit from '../views/Edit.vue';
import EditCategory from '../views/EditCategory.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      guest: true,
    },
  },
  {
    path: '/frame',
    name: 'Iframe',
    component: Iframe,
    meta: {
      guest: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/create',
    name: 'Create',
    component: Create,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/create-category',
    name: 'CreateCategory',
    component: CreateCategory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/edit',
    name: 'Edit',
    component: Edit,
    meta: {
      requiresAuth: true,
      requiresEntry: true,
    },
  },
  {
    path: '/edit-category',
    name: 'EditCategory',
    component: EditCategory,
    meta: {
      requiresAuth: true,
      requiresCat: true,
    },
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(
      (record) => record.meta.requiresAuth && record.meta.requiresEntry,
    )
  ) {
    if (
      store.getters.isLoggedIn &&
      'entry' in to.params &&
      Object.keys(JSON.parse(to.params.entry)).length > 0
    ) {
      next();
      return;
    }
    next('/dashboard');
  } else if (
    to.matched.some(
      (record) => record.meta.requiresAuth && record.meta.requiresCat,
    )
  ) {
    if (
      store.getters.isLoggedIn &&
      'category' in to.params &&
      Object.keys(JSON.parse(to.params.category)).length > 0
    ) {
      next();
      return;
    }
    next('/categories');
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
